<template>
  <div v-if="item" class="department-item box overflow-h">
    <!-- Head -->
    <div class="department-item__head flex ai-c ggap-10 p-5" :class="{ active: isOpen }" @click.stop="isOpen = !isOpen">
      <button class="department-item__head-btn btn rounded cube-24">
        <BaseIcon class="ic-16 black" :icon="isOpen ? 'arrow-down' : 'arrow-right'" />
      </button>
      {{ item.department && item.department.name }}

      <small
        v-if="item.deletable_manual_users && item.deletable_manual_users.length"
        class="marker red"
        v-tippy="'Удалено'"
      >
        {{ item.deletable_manual_users.length }}
      </small>

      <small v-if="item.deleted && item.deleted.length" class="marker red" v-tippy="'Удалено'">
        {{ item.deleted.length }}
      </small>

      <small v-if="item.new_manual_users && item.new_manual_users.length" class="marker green" v-tippy="'Добавлено'">
        {{ item.new_manual_users.length }}
      </small>

      <small v-if="item.new && item.new.length" class="marker green" v-tippy="'Добавлено'">
        {{ item.new.length }}
      </small>

      <small v-if="item.department && item.department.deleted" class="marker red"> Удалено </small>
    </div>

    <!-- Body -->
    <div v-if="isOpen" class="department-item__body p-10 flex fw ggap-5">
      <template v-if="item.deletable_manual_users && item.deletable_manual_users.length">
        <div v-for="user of item.deletable_manual_users" :key="user.id" class="pos-r">
          <div class="department-item__icon red" v-tippy="'Удалено'"><BaseIcon class="ic-14 white" icon="minus" /></div>
          <ItemUser
            :item="{
              id: user.id,
              avatar: user.avatar,
              text: user.fullname || user.text,
              position: user.specialization || user.position || 'Без должности'
            }"
            :isLink="true"
            :isRemove="false"
          />
        </div>
      </template>

      <template v-if="item.deleted && item.deleted.length">
        <div v-for="user of item.deleted" :key="user.id" class="pos-r">
          <div class="department-item__icon red" v-tippy="'Удалено'"><BaseIcon class="ic-14 white" icon="minus" /></div>
          <ItemUser
            :item="{
              id: user.id,
              avatar: user.avatar,
              text: user.fullname || user.text,
              position: user.specialization || user.position || 'Без должности'
            }"
            :isLink="true"
            :isRemove="false"
          />
        </div>
      </template>

      <template v-if="item.new_manual_users && item.new_manual_users.length">
        <div v-for="user of item.new_manual_users" :key="user.id" class="pos-r">
          <div class="department-item__icon green" v-tippy="'Добавлено'">
            <BaseIcon class="ic-14 white" icon="plus" />
          </div>
          <ItemUser
            :item="{
              id: user.id,
              avatar: user.avatar,
              text: user.fullname || user.text,
              position: user.specialization || user.position || 'Без должности'
            }"
            :isLink="true"
            :isRemove="false"
          />
        </div>
      </template>

      <template v-if="item.new && item.new.length">
        <div v-for="user of item.new" :key="user.id" class="pos-r">
          <div class="department-item__icon green" v-tippy="'Добавлено'">
            <BaseIcon class="ic-14 white" icon="plus" />
          </div>
          <ItemUser
            :item="{
              id: user.id,
              avatar: user.avatar,
              text: user.fullname || user.text,
              position: user.specialization || user.position || 'Без должности'
            }"
            :isLink="true"
            :isRemove="false"
          />
        </div>
      </template>

      <div
        v-if="!item.deletable_manual_users && !item.new_manual_users && !item.deleted && !item.new"
        class="flex ai-c jc-c w-100"
      >
        Кастомных пользователей не найдено
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, toRefs } from 'vue'
import { BaseIcon, ItemUser } from '@/components'

// Props
const props = defineProps(['item'])

// Data
const { item } = toRefs(props)
const isOpen = ref(false)
</script>

<style lang="scss" scoped>
.department-item {
  &__head {
    height: 40px;
    cursor: pointer;

    &:hover {
      background-color: var(--grey-l);
    }
    &:active {
      background-color: var(--grey);
    }
    &.active {
      background-color: var(--box);
      color: var(--primary);
    }

    &.active &-btn {
      background-color: var(--primary-el);
      svg {
        fill: var(--primary);
      }
    }
  }

  &__body {
    border-top: var(--b1);
    background-color: var(--form);
  }

  &__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -3px;
    top: -3px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.green {
      background-color: var(--green);
    }
    &.red {
      background-color: var(--red);
    }
  }
}
</style>
